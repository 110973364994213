<template>
  <div class="filter-category">
    <div
      @click="selectCategory"
      class="filter-category__title"
      :class="{ 'filter-category__title_selected': category.selected }"
    >
      {{ category.name }}
    </div>

    <div class="responsibility-list ml-4">
      <div
        @click="selectResponsibility(respons)"
        v-for="respons in category.responsibility"
        :key="respons.id"
        class="responsibility-list__item"
        :class="{
          'responsibility-list__item_selected': respons.selected
        }"
      >
        {{ respons.name }}
      </div>
    </div>
    <filter-categories-category
      @select-child-filter="onSelectChildFilter(categ.id)"
      class="ml-4"
      v-for="categ in category.child"
      :key="categ.id"
      :category="categ"
    />
  </div>
</template>

<script>
const disableChildrens = categs => {
  const categsIds = Object.keys(categs);
  if (categsIds.length) {
    categsIds.forEach(id => {
      categs[id].responsibility.forEach(resp => {
        resp.selected = false;
      });
      categs[id].selected = false;
      disableChildrens(categs[id].child);
    });
  }
};
const configSelfAndChildSelected = category => {
  category.responsibility.forEach(resp => {
    resp.selected = false;
  });
  category.selected = !category.selected;
  disableChildrens(category.child);
};
/*

    {
      '1':{
        id: '1',
        selected: false,
        responsibility: [
          {
            id: '1',
            selected: false,
          }
        ],
        children: {
          '909':{
            id: '909',
            children: null,
            selected: false,
            responsibility: [
              {
                id: '1',
                selected: false,
              }
            ],
          }
        }
      }
    
    }
    */

export default {
  name: "FilterCategoriesCategory",
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    onSelectChildFilter(categId) {
      this.category.child[categId].selected = false;
      this.$emit("select-child-filter");
    },
    selectResponsibility(respons) {
      respons.selected = !respons.selected;
      this.$emit("select-child-filter");
    },
    selectCategory() {
      configSelfAndChildSelected(this.category);
    }
  }
};
</script>

<style scoped lang="scss">
.filter-category {
  display: flex;
  flex-direction: column;
}
.filter-category__responsibility-list {
}

.responsibility-list {
  display: flex;
  flex-direction: column;
}
.responsibility-list__item {
  cursor: pointer;
  padding-bottom: 21px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.41px;
  &_selected {
    color: var(--color-prime);
  }
}

.filter-category__title {
  padding-bottom: 21px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.41px;
  cursor: pointer;
  &_selected {
    color: var(--color-prime);
  }
}
</style>
