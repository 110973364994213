<template>
  <div class="control-panel">
    <div class="control-panel__head">
      <div class="control-panel__ell">
        <slot name="left"></slot>
      </div>
      <div class="control-panel__ell">
        <div :class="['btn-filter', { 'btn-filter--active': isShown }]" @click="isShown = !isShown">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'btn-filter__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.5 16.3c-.4 0-.8-.4-.8-.8V9.3S3 4.5.9 1.9c-.2-.2-.2-.5-.1-.8.2-.2.4-.4.7-.4h14c.3 0 .6.2.7.4.1.3.1.6-.1.8-2 2.6-5.7 7.4-5.7 7.4V15.5c0 .4-.4.8-.8.8H7.5zm1-7.2l5.4-6.8H3.1l5.4 6.8z" fill="#020202"/></svg>
          <span class="btn-filter__text">Фильтр</span>
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'btn-filter__caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
        </div>
      </div>
    </div>
    <div  class="control-panel__body" v-if="isShown">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ControlPanel',
  components: {
  },
  data() {
    return {
      isShown: false,
    };
  },
};
</script>

<style scoped lang="scss">

// vue-control
.control-panel {
  margin-bottom: 20px;
  .control-panel__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  @include respond-to('sm') {
    margin-bottom: 30px;
    .control-panel__head {
      margin-bottom: 30px;
    }
  }
}

</style>
