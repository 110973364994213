var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'nav-btn',
  {
    'nav-btn--shown': _vm.shownCategory,
    'nav-btn--responsive': _vm.responsive,
  }
]},[_c('div',{staticClass:"nav-btn__toggle",on:{"click":function($event){_vm.shownCategory = !_vm.shownCategory}}}),_c('div',{staticClass:"nav-btn__list"},_vm._l((_vm.list),function(item){return _c(_vm.mode === 'filters' && (!('name' in item) || item.name === _vm.$route.name)  ? 'div' : 'router-link',{key:item.index,tag:"component",staticClass:"nav-btn__ell",attrs:{"to":{
        name: item.name,
        query: item.value ? { filters: item.value } : {}
      }},on:{"click":function($event){return _vm.onFilterBtnClick(item)}}},[_c('div',{class:[
          'nav-btn__link',
          {
            'nav-btn__link_active': (item.value === _vm.$route.query.filters || !('filters' in _vm.$route.query) && item.value === _vm.value) && item.name === _vm.$route.name
          }
        ]},[_c('img',{staticClass:"nav-btn__icon",attrs:{"svg-inline":"","src":item.icon,"alt":""}}),_c('span',{staticClass:"nav-btn__name"},[_vm._v(_vm._s(item.label))])])])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }