<template>
  <div>
    <FiltersOrNavBtnList
      :list="navList"
      v-bind="$attrs"
      v-on="$listeners"
    ></FiltersOrNavBtnList>
  </div>
</template>

<script>
import {
  ASSISTANCE_NEED_APPLICATIONS,
  ASSISTANCE_NEED_RESPONSES
} from '@router/variable';
import FiltersOrNavBtnList from "@component/Module/FiltersOrNavBtns";

export default {
  name: "FilterBtnList",
  components: {
    FiltersOrNavBtnList
  },
  data() {
    return {
      navList: [
        {
          label: 'Созданные заявки',
          icon: require('@img/icons/app-new-need.svg'),
          name: ASSISTANCE_NEED_APPLICATIONS,
          value: ''
        },
        {
          label: 'Отклики',
          name: ASSISTANCE_NEED_RESPONSES,
          icon: require('@img/icons/app-responses-need.svg'),
          value: ''
        },
        // {
        //   label: 'Заявки в работе',
        //   icon: require('@img/icons/app-active-need.svg'),
        //   name: ASSISTANCE_NEED_APPLICATIONS,
        //   value: this.$ENUM.REQUEST_STATUSES.IN_WORK
        // },
        {
          label: 'Выполненные',
          icon: require('@img/icons/app-completed-need.svg'),
          name: ASSISTANCE_NEED_APPLICATIONS,
          value: this.$ENUM.REQUEST_STATUSES.COMPLETED
        },
        {
          label: 'Завершенные',
          icon: require('@img/icons/app-completed-need.svg'),
          name: ASSISTANCE_NEED_APPLICATIONS,
          value: this.$ENUM.REQUEST_STATUSES.FINISHED
        },
      ],
    };
  }
};
</script>

<style scoped>

</style>
