<template>
  <div
    :class="[
      'request-status',
      {
        [`request-status--${statusKey}`]: statusKey
      }
    ]"
  >
    <span>{{ normalizeStatus.value }}</span>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
export default {
  name: "ApplicationStatus",
  props: {
    status: {
      type: [Object, String],
      default: function () {
        return this.$ENUM.REQUEST_STATUSES.ACTIVE;
      }
    }
  },
  computed: {
    ...mapState({
      statuses: state => state.enums.requestsStatuses
    }),
    normalizeStatus() {
      let status = {};

      if (typeof this.status === 'string') {
        let findStatus = this.statuses.find(item => item.key === this.status);
        if (findStatus) {
          status = findStatus;
        }
      } else if (typeof this.status === 'object') {
        status = this.status;
      }

      return status;
    },
    statusKey() {
      let statusKey = null;

      let {
        ACTIVE,
        CANCELED,
        COMPLETED,
        IN_WORK,
        FINISHED
      } = this.$ENUM.REQUEST_STATUSES;

      switch (this.normalizeStatus?.key) {
        case ACTIVE:
          statusKey = 'active';
          break;
        case CANCELED:
          statusKey = 'canceled';
          break;
        case FINISHED:
          statusKey = 'finished';
          break;
        case COMPLETED:
          statusKey = 'completed';
          break;
        case IN_WORK:
          statusKey = 'in_work';
          break;
      }

      return statusKey;

    }
  },
  methods: {
    ...mapActions('enums', ['getRequestsStatuses']),
  },
  async mounted() {
    await this.getRequestsStatuses();
  }
};
</script>

<style lang="scss" scoped>
  .request-status {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    &::before {
      opacity: 0.8;
    }
    &--active {
      &::before {
        background-color: #EEBA35;
      }
      color: #EEBA35;
    }
    &--canceled {
      &::before {
        background-color: #FF3431;
      }
      color: #FF3431;
    }
    &--completed {
      &::before {
        background-color: #35C759;
      }
      color: #35C759;
    }
    &--in_work {
      &::before {
        background-color: #B5D94F;
      }
      color: #B5D94F;
    }
    &--finished {
      &::before {
        background-color: #BDBDBD;
      }
      color: #BDBDBD;
    }
  }
</style>
