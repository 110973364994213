<template>
  <div class="page-content">

    <div class="container container--asside-content">

      <FilterBtnList
        responsive
        v-model="selectedServiceRequestStatus"
      ></FilterBtnList>

      <ControlPanel>

        <FilterContent
          slot="body"
          @applyFilters="onApplyFilters"
          class="filter--content"
          v-model="filters"
        />

      </ControlPanel>

      <template v-if="loading">

        <v-progress-linear
          indeterminate
        ></v-progress-linear>

      </template>

      <template v-else-if="serviceRequestCardList.length">


        <div class="list">

          <template v-for="(serviceRequest, index) in serviceRequestCardList">

            <CardRequest
              class="list__item"
              :status="serviceRequest.status"
              :person="serviceRequest.person"
              :category="serviceRequest.category"
              :address="serviceRequest.address"
              :price="serviceRequest.price"
              :about="serviceRequest.about"
              :key="index"
            >
              <template #footer>
                <div class="footer-block">
                  <router-link
                    class="footer__btn detail-btn"
                    :to="{
                      name: $routeName.ASSISTANCE_NEED_APPLICATION,
                      params: { applicationId: serviceRequest.id },
                    }"
                  >Подробнее</router-link>

                  <!-- <div class="chat-button">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'chat-button__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.929.001H16.07C17.137.001 18 .864 18 1.93v10.285a1.928 1.928 0 01-1.929 1.929H13.79l.35 3.143a.643.643 0 01-1.07.549l-4.102-3.692H1.93A1.929 1.929 0 010 12.215V1.93C0 .864.863 0 1.929 0z" fill="#FF6B69"/></svg>
                  <div class="chat-button__text">Чат с исполнителем</div>
                </div> -->

                  <!-- <BlockChat class="footer-block__item" v-if="!selectedServiceRequestStatus && listMode !== 'asside'" title="чат с исполнителем" /> -->

                  <template v-if="serviceRequest.status.key === 'ACT'">
                    <router-link
                      class="footer__btn executor-btn"
                      :to="{
                      name: $routeName.ASSISTANCE_NEED_RESPONSES,
                      query: { request: serviceRequest.id }
                    }"
                    >
                      Подобрать исполнителя
                    </router-link>
                  </template>
                </div>
              </template>

            </CardRequest>
          </template>

        </div>

        <InfiniteLoading
          :forceUseInfiniteWrapper="true"
          :distance="500"
          error="Opps, something went wrong. Reload page."
          @infinite="infiniteHandler"
        >

          <div slot="no-more" />
          <div slot="no-results" />

        </InfiniteLoading>

      </template>

      <template v-else>

        <div>Нет заявок</div>

      </template>
    </div>
  </div>
</template>

<script>

import InfiniteLoading from "vue-infinite-loading";
import ControlPanel from '@component/Module/ControlPanel';
import CardRequest from '@component/Module/CardRequest';
import FilterContent from '@component/Module/FilterContent.vue';
import { mapState, mapActions } from 'vuex';
import FilterBtnList from "@component/Module/NeedServiceRequestList/FilterBtnList";

import { preparePhoto } from '@library/helpers';

const normalizeFilters = filters => {
  return {
    ...filters,
    category: filters.category.join(','),
    responsibility: filters.responsibility.join(','),
  };
};

export default {
  components: {
    FilterBtnList,
    ControlPanel,
    FilterContent,
    CardRequest,
    InfiniteLoading
  },
  data() {
    return {
      serviceRequestList: [],
      selectedServiceRequestStatus: '',
      loading: true,
      page: 1,
      pageSize: 10,
      filters: {
        category: [],
        responsibility: [],
        ordering: '-created_at',
      },
    };
  },
  watch: {
    async selectedServiceRequestStatus(v) {
      this.loading = true;
      if (this.$route.query?.filters !== v) {
        await this.$router.push({query: {filters: v}});
      }
      await this.onResetServiceRequestList();
      await this.getServiceRequestList();
      this.loading = false;
    },
    async isAddedServiceRequest(v) {
      this.loading = true;
      if (v && !this.selectedServiceRequestStatus) {
        await this.onResetServiceRequestList();
        await this.getServiceRequestList();
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories,
      statuses: state => state.enums.requestsStatuses,
      user: state => state.user.user,
      isAddedServiceRequest: state => state.user.isAddedServiceRequest
    }),
    serviceRequestCardList() {
      return this.serviceRequestList.map(
        ({
          date,
          id,
          family,
          address: { address },
          category: { name: category },
          price,
          about,
          status: requestStatusKey,
        }) => {
          const person = {
            id: family.id,
            name: `${family.first_name} ${family.last_name}`,
            image: preparePhoto(family.photo),
            role: 'Нуждается в помощи',
            date,
          };

          return {
            date,
            id,
            person,
            address,
            category,
            price,
            about,
            status: this.statuses.find(status => status.key === requestStatusKey),
          };
        }
      );
    },
  },
  methods: {
    ...mapActions('enums', ['getRequestsStatuses']),
    async getServiceRequestList() {
      try {

        const response = await RequestManager.Service.getRequests(
          this.user.id,
          {
            status: this.selectedServiceRequestStatus,
            page: this.page,
            ...normalizeFilters(this.filters),
            page_size: this.pageSize,
            parent_is_null: true
          }
        );

        if (response.length) {

          if (this.page === 1) {
            await this.onResetServiceRequestList();
          }

          const combineApplications = [...response];

          response.map(serviceRequest => {
            let { child } = serviceRequest;

            if (child?.length) {

              child.map(childServiceRequest => {
                for( let i = 0; i < response.length; i++) {
                  if (childServiceRequest.id === response[i].id) {
                    combineApplications.splice(i, 1);
                  }
                }
              });

            }

          });

          this.serviceRequestList.push(...combineApplications);
        }
        return response;

      } catch (err) {
        console.log(err);
      }
    },
    async infiniteHandler($state) {
      if (this.serviceRequestList.length < this.pageSize) {
        $state.complete();
        return;
      }

      this.page += 1;
      const serviceRequestList = await this.getServiceRequestList();

      if (!serviceRequestList || serviceRequestList.length < this.pageSize) {
        $state.complete();
        console.log('complete');
      } else {
        $state.loaded();
        console.log('loaded');
      }
    },
    onResetServiceRequestList() {
      this.page = 1;
      this.serviceRequestList = [];
    },
    onChangeStateLoading() {
      this.loading = !this.loading;
    },
    async onApplyFilters() {
      this.onChangeStateLoading();
      await this.onResetServiceRequestList();
      await this.getServiceRequestList();
      this.onChangeStateLoading();
    }
  },
  async mounted() {
    await this.getRequestsStatuses();
    await this.getServiceRequestList();

    this.loading = false;
    this.selectedServiceRequestStatus = this.$route.query?.filters ?? '';
  },
};
</script>

<style lang="scss" scoped>
.list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.list__item{
  width: 100%;
  @include respond-to('sm') {
    width: 49%;
    &_asside{
      width: 100%
    }
  }
}

.footer-block{
  margin-top: auto;
  padding: 10px;

  //border-top: 1px solid rgba(163, 163, 163, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__btn {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
}

.detail-btn {
  color: #757579;

}
.executor-btn{
  color: var(--color-prime);
}
</style>
