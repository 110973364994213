<template>
  <div :class="[
    'nav-btn',
    {
      'nav-btn--shown': shownCategory,
      'nav-btn--responsive': responsive,
    }
  ]">
    <div class="nav-btn__toggle" @click="shownCategory = !shownCategory"></div>
    <div class="nav-btn__list">
      <component
        :is="mode === 'filters' && (!('name' in item) || item.name === $route.name)  ? 'div' : 'router-link'"
        :to="{
          name: item.name,
          query: item.value ? { filters: item.value } : {}
        }"
        @click="onFilterBtnClick(item)"
        v-for="item in list"
        class="nav-btn__ell"
        :key="item.index"
      >
        <div
          :class="[
            'nav-btn__link',
            {
              'nav-btn__link_active': (item.value === $route.query.filters || !('filters' in $route.query) && item.value === value) && item.name === $route.name
            }
          ]">
          <img svg-inline :src="item.icon" class="nav-btn__icon" alt="" />
          <span class="nav-btn__name">{{ item.label }}</span>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    mode: {
      type: String,
      default: 'filters',
      validator(value) {
        return ['filters', 'links'].indexOf(value) !== -1;
      },
    },
    responsive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shownCategory: false,
    };
  },
  methods: {
    onFilterBtnClick(item) {
      if (this.mode === 'links') return;
      this.$emit('input', item.value === this.value ? '' : item.value);
    },
  },
};
</script>

<style scoped lang="scss">
// nav-btn
.nav-btn {
  margin-bottom: 30px;
  .nav-btn__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 52px;
    margin-bottom: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 17px;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:before {
      content: 'Показать категории заявок';
    }
    &:hover {
      box-shadow: 0 0 6px rgba(151, 161, 176, 0.25);
    }
  }
  .nav-btn__list {
    display: none;
  }
  .nav-btn__link {
    display: flex;
    align-items: center;
    height: 52px;
    margin-bottom: 10px;
    padding: 6px 20px;
    border-radius: 14px;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    .nav-btn__icon {
      margin-right: 12px;
      path {
        fill: var(--color-prime);
      }
    }
    .nav-btn__name {
      color: #000000;
      font-size: 17px;
      font-weight: 600;
    }
    &:hover {
      box-shadow: 0 3px 0 rgba(151, 161, 176, 0.25);
    }
    &_active {
      border: 2px solid var(--color-prime);
    }
  }
  &--shown {
    .nav-btn__toggle {
      color: #ffffff;
      background-color: var(--color-prime);
      box-shadow: 0 0 6px rgba(151, 161, 176, 0.25);
      &:before {
        content: 'Свернуть категории заявок';
      }
    }
    .nav-btn__list {
      display: block;
    }
  }
  &--responsive {
    @include respond-to('md') {
      .nav-btn__list {
        display: flex;
        margin: 0 -10px;
      }
      .nav-btn__ell {
        margin-bottom: 0;
        padding: 0 10px;
      }
      .nav-btn__toggle {
        display: none;
      }
      .nav-btn__link {
        justify-content: center;
      }
    }
  }
  @include respond-to('sm') {
    .nav-btn__toggle {
      min-height: 56px;
      font-size: 19px;
    }
    .nav-btn__link {
      height: 56px;
      .nav-btn__name {
        font-size: 19px;
      }
    }
  }

}
</style>
