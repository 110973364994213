<template>
  <div v-on="$listeners" class="filter" :class="{ filter_content: !isAsside }">
    <div class="filter__title">Фильтр</div>
    <div class="filter__block">
      <div class="filter__label">Категории помощи</div>
      <v-progress-linear v-if="loadingCategories" indeterminate></v-progress-linear>
      <div v-else class="filter__row">
        <FilterCategories :is-asside="isAsside" v-model="categoriesFilters" :categories="categories" />
      </div>
    </div>
    <v-radio-group v-model="order">
      <div class="filter__row">
        <div class="filter__col">
          <div class="filter__block">
            <div class="filter__label">Вознаграждение</div>
            <div class="filter__content">
              <v-radio label="По убыванию" value="price" />
              <v-radio label="По возрастанию" value="-price" />
            </div>
          </div>
        </div>
        <div class="filter__col">
          <div class="filter__block">
            <div class="filter__label">Растояние</div>
            <div class="filter__content">
              <v-radio label="По удалённости" value="remoteness" />
              <v-radio label="Неважно" value="every" />
            </div>
          </div>
        </div>
        <div class="filter__col">
          <div class="filter__block">
            <div class="filter__label">Дата создания</div>
            <div class="filter__content">
              <v-radio label="От новых заявок к старым" value="-created_at" />
              <v-radio label="От старых заявок к новым" value="created_at" />
            </div>
          </div>
        </div>
      </div>
    </v-radio-group>
    <div class="filter__content">
      <button @click="$emit('applyFilters')" class="btn btn--prime">Применить</button>
      <button @click="onResetFilters" class="filter__btn-reset">Сбросить данные фильтра</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FilterCategories from '@component/Module/Filters/FilterCategories.vue';

export default {
  name: 'FilterContent',
  components: {
    FilterCategories,
  },
  props: {
    isAsside: {
      required: false,
      default: false,
    },
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingCategories: false,
    };
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories,
    }),
    categoriesFilters: {
      set(value) {
        this.$emit('input', { ...this.value, ...value });
      },
      get() {
        return { category: this.value.category, responsibility: this.value.responsibility };
      },
    },
    order: {
      set(value) {
        this.$emit('input', { ...this.value, ordering: value });
      },
      get() {
        return this.value.ordering;
      },
    },
  },

  async created() {
    if (!this.categories.length) {
      this.loadingCategories = true;
      await this.$store.dispatch('enums/getCategories');
      this.loadingCategories = false;
    }
  },
  methods: {
    onResetFilters() {
      this.categoriesFilters = {
        category: [],
        responsibility: [],
      };
      this.$emit('applyFilters');
    },
  },
};
</script>

<style scoped lang="scss">
// filter
// .filter_content - модификатор применяющийся в случае оттображения НЕ в карте
.filter {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  margin: 0px -14px;
  .filter__title {
    display: none;
  }
  .filter__label {
    margin-bottom: 16px;
    padding: 14px 16px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    background-color: #ededf4;
  }
  .filter__btn-reset {
    display: block;
    margin: 20px auto 0;
    color: #b3b3b8;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: var(--color-prime-active);
    }
  }
  .filter__block {
    margin-bottom: 15px;
  }
  .filter__content {
    padding: 0 15px;
  }
  .btn {
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
  }
  &.filter_content {
    margin: 0px;
    position: relative;
    padding: 30px 14px;
    background: #ffffff;
    border: 1px solid rgba(118, 118, 128, 0.12);
    border-radius: 10px;
    border-bottom: none;
    .filter__title {
      display: block;
      margin-bottom: 16px;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
    }
    .filter__label {
      border-radius: 5px;
    }
    .filter__block {
      margin-bottom: 20px;
    }
    .filter__content {
      padding: 0;
    }
  }
  @include respond-to("sm") {
    .filter__label {
      margin-bottom: 20px;
      padding: 15px 20px;
      font-size: 18px;
    }
    .filter__btn-reset {
      margin: 24px auto 0;
      font-size: 18px;
    }
    .filter__block {
      margin-bottom: 35px;
    }
    &.filter_content {
      .filter__title {
        margin-bottom: 28px;
        font-size: 19px;
      }
      .filter__btn-reset {
        position: absolute;
        right: 15px;
        top: 30px;
        margin: 0;
      }
      .filter__block {
        margin-bottom: 30px;
      }
    }
  }
  @include respond-to("md") {
    &.filter_content {
      .filter__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        .filter__col {
          width: 33.333%;
          padding: 0 8px;
        }
      }
    }
  }
}
</style>
