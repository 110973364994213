<template>
  <div class="filter-categories">
    <div
      v-for="categ in normalizedCategories"
      :key="categ.id"
      class="filter-categories__category"
      :class="{ 'filter-categories__category_asside': isAsside }"
    >
      <div class="filter__content">
        <FilterCategoriesCategory @select-child-filter="onSelectChildFilter(categ.id)" :category="categ" />
      </div>
    </div>
  </div>
</template>

<script>
import { normalizeCategories, getSelectedFilters, setSlectedFilters, equalsIgnoreOrder } from '@component/Module/Filters/helpers';

import FilterCategoriesCategory from '@component/Module/Filters/FilterCategoriesCategory.vue';
/*
      value: {
        category: [1, 2],
        responsibility: [2, 3]
      },
    */
export default {
  components: {
    FilterCategoriesCategory,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    isAsside: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      normalizedCategories: [],
      innerValue: {
        category: [],
        responsibility: [],
      },
    };
  },
  watch: {
    categories: {
      handler() {
        this.normalizedCategories = normalizeCategories(this.categories, this.value);
      },
      immediate: true,
    },
    normalizedCategories: {
      handler() {
        console.log('watch normalizedCategories');
        this.innerValue = getSelectedFilters(this.normalizedCategories);
      },
      deep: true,
    },
    value: {
      handler() {
        this.innerValue = { ...this.value };
        setSlectedFilters(this.value, this.normalizedCategories);
      },
      deep: true,
      immediate: true,
    },
    innerValue: {
      handler() {
        if (
          !equalsIgnoreOrder(this.value.category, this.innerValue.category) ||
          !equalsIgnoreOrder(this.value.responsibility, this.innerValue.responsibility)
        ) {
          this.$emit('input', { ...this.innerValue });
        }
      },
    },
  },
  methods: {
    onSelectChildFilter(categId) {
      this.normalizedCategories[categId].selected = false;
    },
  },
};
</script>

<style lang="scss">
.filter-categories {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}
.filter-categories__category {
  width: 100%;
  @include respond-to("md") {
    width: 33.333%;
    padding: 0 8px;
    &_asside {
      width: 100%;
      padding: 0 15px;
    }
  }
}
</style>
