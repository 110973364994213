<template>
  <div class="card-request">
    <div class="card-request__head">
      <BlockPerson :person="person" />

      <ApplicationStatus :status="status"></ApplicationStatus>
    </div>
    <div class="card-request__body">
      <div class="card-request__block">
        <div class="ic-ell">
          <div class="ic-ell__icon">
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
          </div>

          <div class="ic-ell__text">{{ category }}</div>
        </div>
      </div>
      <div class="card-request__row">
        <!-- <div class="card-request__block">
          <div class="ic-ell">

            <div class="ic-ell__icon">
              <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
            </div>

            <div class="ic-ell__text">1,3 км до места оказания услуги</div>

          </div>
        </div> -->
        <div class="card-request__block">
          <div class="ic-ell">
            <div class="ic-ell__icon">
              <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
            </div>

            <div class="ic-ell__text">Адрес: {{ address }}</div>
          </div>
        </div>
        <div class="card-request__block">
          <div class="ic-ell">
            <div class="ic-ell__icon">
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
            </div>

            <div class="ic-ell__text">Цена заказчика: {{ price }} руб./день</div>
          </div>
        </div>
        <!-- <div class="card-request__block">
          <div class="ic-ell">

            <div class="ic-ell__icon">
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
            </div>

            <div class="ic-ell__text">Цена исполнителя: 700 руб./день</div>

          </div>
        </div> -->
      </div>
      <div v-if="about" class="card-request__block">
        <div class="card-request__descr">{{ about }}</div>
      </div>
    </div>
    <slot name="footer"></slot>
    <slot name="footer-1"></slot>
    <slot name="footer-2"></slot>
  </div>
</template>

<script>
import BlockPerson from '@component/Module/BlockPerson';
import ApplicationStatus from '@component/UI/ApplicationStatus';

export default {
  name: 'CardRequest',
  components: {
    ApplicationStatus,
    BlockPerson,
  },
  props: {
    status: Object,
    person: Object,
    address: String,
    category: String,
    price: [String, Number],
    about: String,
  },
};
</script>

<style scoped lang="scss">

// card-request
.card-request {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  @include respond-to('md') {
    margin-bottom: 40px;
  }
  .card-request__head {
    position: relative;
    padding: 15px;
    .request-status {
      position: absolute;
      top: 12px;
      right: 30px;
    }
  }
  .card-request__body {
    padding: 0 15px;
    .card-request__block {
      width: 48%;
      padding: 5px 0 15px;
      border-bottom: 1px solid rgba(163, 163, 163, 0.3);
      // &:last-child {
      //   border-bottom: none;
      // }
      .ic-ell {
        //align-items: flex-start;
      }
    }
    .card-request__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;

      @media(min-width: 1440px){
        justify-content: flex-start;
      }
      .card-request__block {
        width: 100%;
        @include respond-to('md') {
          width: 48%;
        }
      }
    }
  }
  .card-request__descr {
    padding: 5px 0;
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
  }

  .footer-block {
    padding: 15px;
  }
}

</style>
