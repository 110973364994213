<template>
  <div class="block-person">
    <div class="block-person__avatar">
      <img
        v-if="person.image"
        :src="person.image"
        class="block-person__img"
        alt="avatar"
      >
      <img
        v-else
        :src="require('@img/user-avatar.svg')"
        alt=""
      >
    </div>
    <div class="block-person__cont">
      <div
        v-if="person.role"
        class="block-person__role"
      >{{ person.role }}</div>
      <div
        v-if="person.name"
        class="block-person__name"
      >{{ person.name }}</div>

      <div
        v-if="person.rate && !hideRate"
        class="block-person__rating rating"
      >
        <v-rating
          hover
          readonly
          length="5"
          size="20"
          v-model="person.rate"
        />

        <div class="rating__vue">{{ person.rate }}</div>
      </div>

      <div v-if="person.date" class="block-person__date">

        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block-person__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.338 5.617L4.113 8.842 2.387 7.115l.584-.584 1.142 1.143 2.64-2.64.585.583zm1.32-4.367c.305 0 .559.11.762.33.22.203.33.457.33.762v7.566c0 .305-.11.567-.33.787a1.034 1.034 0 01-.762.305H1.092a1.12 1.12 0 01-.787-.305A1.12 1.12 0 010 9.908V2.342c0-.305.102-.559.305-.762.22-.22.482-.33.787-.33h.533V.158h1.092V1.25h4.316V.158h1.092V1.25h.533zm0 8.658V3.967H1.092v5.941h7.566z" fill="#0C72D6"/></svg>
        {{ person.date }}

      </div>

      <div
        v-if="person.status"
        class="block-person__status"
      >{{ person.status }}</div>

      <div
        v-if="person.gender"
        class="block-person__info"
      >
        Пол: <span>{{ person.gender }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BlockPerson",
  props: {
    person: Object,
    hideRate: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">

// block-person
.block-person {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .block-person__avatar {
    width: 65px;
    height: 65px;
    min-width: 65px;
    min-height: 65px;
    border-radius: 50%;
    overflow: hidden;

    .block-person__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .block-person__cont {
    margin-left: 15px;
    .block-person__role {
      margin-bottom: 5px;
      color: rgba(2, 2, 2, 0.3);
      font-size: 13px;
    }
    .block-person__name {
      max-width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
      color: #020202;
      font-size: 17px;
      font-weight: 600;
    }
    .block-person__date {
      margin-bottom: 2px;
      color: rgba(2, 2, 2, 0.3);
      font-size: 13px;
      .block-person__icon {
        margin-right: 5px;
        path {
          fill: var(--color-prime);
        }
      }
    }
    .block-person__info {
      color: #000000;
      font-size: 15px;
      span {
        color: rgba(0, 0, 0, 0.4);
      }
    }
    .block-person__status {
      color: #35C759;
      font-size: 13px;
    }
    .block-person__rating {
      &.rating {
        padding-top: 0;
      }
    }
  }
  &--lg {
    @media(max-width: 768px) {
      flex-wrap: wrap;
    }
    .block-person__avatar {
      @media(max-width: 768px) {
        margin: 0 auto 15px auto;
      }
    }
  }
}

</style>
