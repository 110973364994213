const normalizeResponsibility = responsibility => {
  return {
    selected: false,
    ...responsibility
  };
};
/*

    {
      '1':{
        id: '1',
        selected: false,
        responsibility: [
          {
            id: '1',
            selected: false,
          }
        ],
        children: {
          '909':{
            id: '909',
            children: null,
            selected: false,
            responsibility: [
              {
                id: '1',
                selected: false,
              }
            ],
          }
        }
      }
    
    }
    */

const getCateg = (categ, categories, responsibilities) => {
  categories.push(categ);
  responsibilities.push(...categ.responsibility);
  if (categ.child) {
    Object.keys(categ.child).map(categId => {
      getCateg(categ.child[categId], categories, responsibilities);
    });
  }
};
const getCategsAndResp = categs => {
  const categories = [];
  const responsibilities = [];
  Object.keys(categs).map(categId => {
    getCateg(categs[categId], categories, responsibilities);
  });

  return {
    categories,
    responsibilities
  };
};

export const normalizeCategories = (categories, selectedCategsAndRespons) => {
  const normalizedCategs = {};
  categories.map(categ => {
    normalizedCategs[categ.id] = {
      ...categ,
      selected: false,
      child: normalizeCategories(categ.child),
      responsibility: categ.responsibility.map(resp => normalizeResponsibility(resp))
    };
  });
  return normalizedCategs;
};

export const getSelectedFilters = normalizedCategories => {
  const { categories, responsibilities } = getCategsAndResp(normalizedCategories);

  return {
    category: categories.filter(cat => cat.selected).map(el => el.id),
    responsibility: responsibilities.filter(res => res.selected).map(el => el.id)
  };
};

export const setSlectedFilters = (
  { category: selectedCategs, responsibility: selectedResp },
  normalizedCategories
) => {
  const { categories, responsibilities } = getCategsAndResp(normalizedCategories);
  categories.map(categ=>{
    categ.selected = false;
  });
  responsibilities.map(resp=>{
    resp.selected = false;
  });
  selectedCategs.map(categId => {
    const categ = categories.find(el => el.id === categId);
    if (categ) categ.selected = true;
  });

  selectedResp.map(respId => {
    const resp = responsibilities.find(el => el.id === respId);
    if (resp) resp.selected = true;
  });
};

export const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};
